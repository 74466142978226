import request, { baseUrl } from "@/utils/request";

/*
 *@描述: 查询订单详情
 *@参数: id: 订单的id
 *@作者: 金涛
 *@日期: 2021-12-17 15:20:40
 */
export function getOrderDetail(id) {
  return request({
    url: `${baseUrl.api}/order/${id}`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/*
 *@描述: 获取登陆用的vip等级
 *@参数: 无
 *@作者: 金涛
 *@日期: 2022-01-12 20:00:53
 */
export function getUserVipLevel() {
  return request({
    url: `${baseUrl.api}/user/getUserInfo`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

export function getOrderList(data) {
  return request({
    url: `${baseUrl.api}/order/list`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}

<template>
  <div style="background: #fbf7f3; min-height: 100%">
    <NavBar
      v-if="hideBack === 'false'"
      style="z-index: 999"
      :title="$t('route.shoppingOrderList')"
    />
    <van-tabs
      v-model="tab.active"
      @change="onChangeTab"
      sticky
      style="background: #fbf7f3"
    >
      <van-tab
        :title="item.label"
        :key="item.value"
        v-for="item in tab.items"
      />
    </van-tabs>
    <div style="padding: 0 0.36rem; font-size: 0.36rem">
      <van-list
        v-model="loading"
        :finished="orderParams.pageNum >= orderData.pages"
        @load="getOrderData"
      >
        <router-link
          class="u-flex-col order-info"
          :to="`/orderDetails?id=${item.id}&hideBack=${hideBack}`"
          v-for="(item, index) in orderData.list"
          :key="index"
          style="margin-top: 0.36rem"
        >
          <ul>
            <li class="u-flex u-row-between u-col-top">
              <div class="u-flex u-row-between order-item">
                <p class="order-item-p">{{ `${$t("orderNumber")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ item.orderSn }}
                </span>
              </div>
              <span class="order-type">
                {{ item.orderStatusMsg }}
              </span>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("orderDate")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ item.createTime }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("orderAmount")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ `HK $${item.payMoney}` }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("contactMethod")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ item.receivePhone }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("receivers")}：` }}</p>
                <span class="order-item-span">
                  {{ item.receiveName }}
                </span>
              </div>
            </li>
            <li>
              <div class="u-flex u-row-left u-col-top">
                <p class="order-item-p">{{ `${$t("shippingAddress")}：` }}</p>
                <span class="order-item-span u-flex-1">
                  {{ item.receiveAddress }}
                </span>
              </div>
            </li>
          </ul>
        </router-link>
      </van-list>

      <EmptyTip
        v-if="!orderData.list.length && !loading"
        style="margin-top: 0.36rem"
      >
        <span class="empty-tip">{{ $t("emptyOrder") }}</span>
      </EmptyTip>
    </div>
  </div>
</template>
<script>
import EmptyTip from "@/components/EmptyTip";
import { getOrderList } from "@/api/order";
import NavBar from "@/components/NavBar";

export default {
  components: {
    EmptyTip,
    NavBar,
  },
  data() {
    return {
      tab: {
        active: 0,
        items: [
          {
            value: 1,
            label: this.$t("orderStatusAll"),
          },
          {
            value: 2,
            label: this.$t("orderStatusDoing"),
          },
          {
            value: 3,
            label: this.$t("orderStatusDone"),
          },
        ],
      },
      loading: true,
      orderParams: {
        queryStatus: 1,
        pageNum: 0,
        pageSize: 10,
      },
      orderData: {
        total: 0,
        pages: 0,
        list: [],
      },
      hideBack: this.$route.query.hideBack || "false",
    };
  },
  created() {
    this.getOrderData();
  },
  methods: {
    onChangeTab(e) {
      this.orderParams.queryStatus = this.tab.items[e].value;
      this.orderParams.pageNum = 0;
      this.getOrderData();
      console.log(e);
    },
    getOrderData() {
      this.orderParams.pageNum++;
      getOrderList(this.orderParams).then((res) => {
        this.loading = false;
        if (res.code !== 200) return;
        const items = res.data.list || [];
        this.orderData = {
          total: res.data.total,
          pages: res.data.pages,
          list:
            this.orderParams.pageNum > 1
              ? this.orderData.list.concat(items)
              : items,
        };
        console.log(this.orderData);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-tabs__line {
  background-color: #a1844c;
}
/deep/ .van-tab--active {
  color: #a1844c;
}
.order-info {
  font-size: 0.24rem;
  color: #515151;
  background: #ffffff;
  border-radius: 0.08rem;
  box-shadow: 0 0.04rem 0.08rem 0 rgba(0, 0, 0, 0.18);
  border-left: 0.16rem solid #ede2cc;
  padding: 0.12rem 0 0.12rem 0.24rem;

  li:first-child {
    border-bottom: 0.04rem solid rgba(151, 151, 151, 0.12);
    div {
      color: #959392;
    }
  }

  li {
    padding: 0.12rem 0;
  }

  .order-type {
    display: block;
    background: #ede2cc;
    border-radius: 2rem 0 0 2rem;
    font-size: 0.24rem;
    color: #978055;
    padding: 0.02rem 0.16rem 0.02rem 0.24rem;
  }

  .order-item-p {
    width: 1.25rem;
    text-align: justify;
  }

  .order-item-span {
    margin-right: 0.24rem;
    word-break: break-all;
  }
}
</style>
